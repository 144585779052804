import React, { useEffect, useState } from "react";
import staricon from "assets/staricon.svg";
import exclamation from "assets/exclamation.svg";
import CopyIcon from "assets/copytools.jpg";
import DownloadIcon from "assets/downloadtool.jpg";
import { Tooltip } from "react-tooltip";
import { Languages } from "./LanguagesData";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";

export const QualityOutput = ({
  setShowDetailAnalysis,
  analysisResult,
  refDiv,
  setDivHeight,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];

  const sourceLanguageName = Languages.find(
    (lang) => lang?.code === analysisResult?.meta?.source_language_code
  );
  const targetLanguageName = Languages.find(
    (lang) => lang?.code === analysisResult?.meta?.target_language_code
  );

  const analysis = analysisResult?.result;
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.offsetHeight);
    }
  }, [refDiv, analysis]);

  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current?.innerText;

      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
  const downloadXLS = () => {
    const headers = ["Metric", "Score", "Description"];
    const rows = Object.entries(analysis)
      .slice(0, -1)
      .map(([key, value]) => {
        const [score, description] = value;
        return { Metric: key, Score: score, Description: description };
      });
  
    // Create a worksheet from the rows data
    const ws = XLSX.utils.json_to_sheet(rows);
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Analysis Result");
  
    // Write the workbook to an XLSX file
    XLSX.writeFile(wb, "analysis_result.xlsx");
  };
  

  const downloadCSV = () => {
    const headers = ["Metric", "Score", "Description"];
    const rows = Object.entries(analysis)
      .slice(0, -1)
      .map(([key, value]) => {
        const [score, description] = value;
        return [key, score, description];
      });

    const csvContent = [
      headers.join(","),
      ...rows.map((e) => e.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "analysis_result.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const tooltiplist = () => {
    return `<div class="text-sm font-opensans leading-5 py-4 px-2">
        <ul class="list-none flex flex-col gap-3 ">
        <li class="flex items-center gap-2">  <p class="italic"><span class="font-bold not-italic">Scores 0.0 - 2.0:</span> Very Poor Quality</p></li>
        <li class="flex items-center gap-2">  <p class="italic"><span class="font-bold not-italic">Scores 2.0 - 4.0: </span> Poor Quality</p></li>
        <li class="flex items-center gap-2">  <p class="italic"><span class="font-bold not-italic">Scores 4.1 - 6.0:</span> Fair Quality</p></li>
        <li class="flex items-center gap-2">  <p class="italic"><span class="font-bold not-italic">Scores 6.1 - 8.0:</span> Good Quality</p></li>
           <li class="flex items-center gap-2">  <p class="italic"><span class="font-bold not-italic">Scores 8.1 - 10.0:</span> Excellent</p></li>
        </ul>
      </div>`;
  };
  return (
    <div ref={refDiv} className=" w-full">
      <div className="flex gap-2 mt-6 border-b border-[#B0B0B0] pb-3 items-center justify-center text-lg font-opensans">
        {analysis?.overall_score && (
          <>
            <p className="text-[#858585] font-bold text-sm">Overall Score</p>
            <p className="flex gap-1 items-center text-center text-lg bg-[#16548D] text-white rounded-full px-5 py-1">
              {analysis?.Grammar[0]}
              <img src={staricon} alt="staricon" />
            </p>
          </>
        )}
        <div className="flex gap-2 items-center">
          <p className="font-bold text-[#424242]">Good</p>
          <button
            data-tooltip-id="data-value-tooltip"
            className=""
            data-tooltip-html={tooltiplist()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM8.8 9.084V9.6H7.2V8.4C7.2 8.18783 7.28429 7.98434 7.43431 7.83431C7.58434 7.68429 7.78783 7.6 8 7.6C8.22726 7.59999 8.44985 7.53544 8.64186 7.41386C8.83387 7.29229 8.98741 7.11869 9.08462 6.91327C9.18182 6.70784 9.2187 6.47904 9.19095 6.25347C9.1632 6.02791 9.07196 5.81487 8.92787 5.63913C8.78377 5.46339 8.59273 5.33218 8.37698 5.26078C8.16122 5.18937 7.92963 5.18069 7.70914 5.23576C7.48865 5.29083 7.28833 5.40738 7.13149 5.57185C6.97465 5.73632 6.86774 5.94195 6.8232 6.1648L5.2536 5.8504C5.35091 5.36407 5.57561 4.9123 5.90474 4.54127C6.23387 4.17024 6.65561 3.89327 7.12687 3.73866C7.59813 3.58405 8.10199 3.55735 8.58695 3.66129C9.0719 3.76524 9.52056 3.99609 9.88706 4.33026C10.2535 4.66443 10.5247 5.08992 10.6729 5.56325C10.821 6.03658 10.8409 6.54076 10.7303 7.02425C10.6197 7.50774 10.3828 7.9532 10.0436 8.31509C9.70447 8.67698 9.27531 8.94233 8.8 9.084Z"
                fill="#B0B0B0"
              />
            </svg>
          </button>
        </div>
        <Tooltip
          id="data-value-tooltip"
          place="bottom-center"
          multiline={true}
          className="z-50 !w-[280px] !opacity-100 !font-opensans font-normal !text-sm !text-start"
        />
      </div>
      <div className="">
        <div className="mt-2 flex items-center justify-center text-[#858585] font-semibold text-sm font-opensans">
          <p className=" flex items-center gap-2">
            {sourceLanguageName?.name}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
            >
              <path
                d="M1.53198 1.56387L6.46806 6.49995L1.53198 11.436"
                stroke="#B0B0B0"
                stroke-width="1.48082"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            {targetLanguageName?.name}{" "}
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full mt-5  max-h-[380px]  overflow-y-auto">
          {analysis &&
            Object.entries(analysis)
              ?.slice(0, -1)
              .map(([key, value], i) => {
                const [score, description] = value;
                return (
                  <div key={i} className="bg-[#F0F5FF] p-3 rounded-lg">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => handleToggle(i)}
                    >
                      <p className="flex items-center gap-3 text-[#858585] text-base font-bold font-opensans">
                        <span className="bg-[#B4D5F3] rounded-md px-1 py-2 text-[#424242] text-sm font-bold">
                          {score}
                        </span>
                        {key}
                      </p>
                      <div className="flex gap-4 items-center">
                        {/* <img src={exclamation} alt="exclamation-icon" /> */}
                        <span
                          className={`text-quotetext-200 cursor-pointer ${
                            activeIndex === i
                              ? "rotate-[316deg]"
                              : "rotate-[135deg]"
                          } arrow transition-all duration-200 ease-linear`}
                        ></span>
                      </div>
                    </div>
                    {activeIndex === i && (
                      <p className="mt-2 text-sm font-normal leading-6 text-[#424242] font-opensans">
                        {description}
                      </p>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
      <div className="bg-white p-3 mt-4  rounded-lg  w-full flex items-center justify-between gap-5 absolute right-0 bottom-0">
        <p
          className="text-base font-opensans text-center font-semibold text-[#5B93FF] cursor-pointer"
          onClick={() => setShowDetailAnalysis(true)}
        >
          See detailed analysis
        </p>
        <div className="flex gap-4 ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
            handleDownload={handleDownload}
            selectedFormat={selectedFormat}
            handleFormatChange={handleFormatChange}
            downloadOutputFormat={downloadOutputFormat}
          />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
    </div>
  );
};
export default QualityOutput;
