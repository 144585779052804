
import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "gatsby";
import ResetButton from "assets/resetbutton.jpg";
import Toolinput from "components/commontools/InsertTool";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import CleanButton from "assets/cleanBtn.svg";
import { Languages } from "components/QualityTools/LanguagesData";
import QualityOutput from "components/QualityTools/QualityOutput";
import DetailAnalysis from "components/QualityTools/DetailAnalysis";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { QaToolFaqData } from "components/FAQ/config";

const QualityAnalysis = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);

const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fetchAnalysis = async () => {
      setLoading(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );

        setAnalysisResult(response);
        setShowOutput(true);

        const {
          source_language_code: sourceLangCode,
          target_language_code: targetLangCode,
          source_text: sourceText,
          translation_text: translationText,
        } = response.meta;

        setInputLang(sourceLangCode);
        setOutputLang(targetLangCode);
        setInputText(sourceText);
        setOutputText(translationText);

        const sourceLangName = Languages.find(
          (lang) => lang.code === sourceLangCode
        )?.name;
        setTranslateFromInput(sourceLangName || "");

        const targetLangName = Languages.find(
          (lang) => lang.code === targetLangCode
        )?.name;
        setTranslateToInput(targetLangName || "");
      } catch (err) {
        console.error("Error fetching analysis result:", err);
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
  }, [displayShareId]);

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;


if (loading)
    return (
      <Layout>
        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>
      </Layout>
    );
  
  return (
    <Layout>
              <SEO
        title="Translation Quality Assurance"
        description="Catch quality issues before delivery with this translation quality assurance tool.  Instantly check fluency, grammar, style, and consistency for better results."
        slug="/tools/translation-quality-assurance"
      />
    <div className="bg-[#F5F5F5]">
      {showDetailAnalysis ? (
        <>
          <DetailAnalysis
            setShowDetailAnalysis={setShowDetailAnalysis}
            analysisResult={analysisResult.result}
          />
        </>
      ) : (
        <>

              <div className="py-7">
                <ToolsBreadcrumb />
              </div>
              <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
                <ToolsBanner
                  text="TRANSLATION QUALITY ASSURANCE TOOL"
                  title="Identify and Resolve Quality Issues Quickly"
                  description="  Enter your source text and its translation, and get detailed
              feedback on accuracy, fluency, and overall translation quality
              instantly."
                />
                <div className="lg:flex gap-3 mt-12">
                  <div className="flex flex-col gap-3 w-full lg:w-[70%]">
                    <div className="lg:flex items-center  rounded-lg shadow-lg ">
                      <Toolinput
                        lang={inputLang}
                        text={inputText}
                        wordCount={wordCountInput}
                        setText={setInputText}
                        setOutputText={setOutputText}
                        setInputLang={setInputLang}
                        setOutputLang={setOutputLang}
                        setTranslateToInput={setTranslateToInput}
                        setTranslateFromInput={setTranslateFromInput}
                        setAnalysisResult={setAnalysisResult}
                        setShowOutput={setShowOutput}
                        setWordCount={setWordCountInput}
                        iscleanBtn={true}
                        refDiv={refDiv}
                        result={analysisResult?.result}
                        divHeight={divHeight}
                        placeholder="Enter your text here"
                        isResult={true}
                      >
                        <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={() => navigate(`/tools/translation-quality-assurance/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                      </Toolinput>

                      <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                      <Toolinput
                        lang={outputLang}
                        text={outputText}
                        wordCount={wordCountOutput}
                        setText={setOutputText}
                        setWordCount={setWordCountOutput}
                        refDiv={refDiv}
                        result={analysisResult?.result}
                        divHeight={divHeight}
                        isTranslation ={true}
                        placeholder="Enter your text here"
                        isResult={true}
                      >
                    
                        <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translation
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={() => navigate(`/tools/translation-quality-assurance/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                  
                      </Toolinput>
                    </div>
                  </div>

                  <ToolOutput
                    // handleClick={handleClick}
                    result={analysisResult?.result}
                    isButtonDisabled={isButtonDisabled}
                    ResetButton={ResetButton}
                    loading={loading}
                    // handleReset={handleReset}
                    showOutput={showOutput}
                    error={error}
                    refDiv={refDiv}
                    buttontext="Check quality"
                    isQuality={true}
                    text="  Your results will appear here. <br /> Please enter your texts and click 'Check quality' to begin."
                  >
                    <QualityOutput
                      setDivHeight={setDivHeight}
                      setShowDetailAnalysis={setShowDetailAnalysis}
                      analysisResult={analysisResult}
                      refDiv={refDiv}
                      divHeight={divHeight}
                    />
                  </ToolOutput>
                </div>
                <p className="w-full text-center  text-base mt-12 pb-4 text-[#9C9C9C]">
                  Assisted by AI tools. Inaccuracies may occur.
                </p>
              
                  <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
                    <div>
                      <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                        Need help translating?
                      </h2>
                      <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                        If your translation needs improvement, reach out to
                        Tomedes for expert translation, editing, and
                        proofreading services.
                      </p>
                    </div>
                    <Link to="/contactus.php">
                      <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                        Contact us
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
         
                <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
                  Enter your source text and its translation, and get detailed
                  feedback on accuracy, fluency, and overall translation quality
                  instantly.
                </p>
              </div>
        
        </>
      )}
    </div>
    <ToolFaq QaToolFaqData={QaToolFaqData} />
    </Layout>
  );
};

export default QualityAnalysis;
